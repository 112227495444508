/* Add here all your css styles (customizations) */
.text-white {
    color: #FFFFFF;
}

.text-purple {
    color: #3b0044;
}

.cube-portfolio .cbp-l-filters-text .cbp-filter-item {
    color: #33;
}
.header-v3 .navbar-default .navbar-nav > .open > a, .header-v3 .navbar-default .navbar-nav > .open > a:hover, .header-v3 .navbar-default .navbar-nav > .open > a:focus {
    color: #3b0044;
}

.cbp-l-grid-agency-title {
    margin-top: 5px;
}

.revolution-mch-1:after {
    background: none;
}

.footer-v1 .footer {
    background: #f9f9f9;
    color: #555;
}

.footer-v1 .footer a, .footer-v1 .footer h2, .footer-v1 .footer p, .footer-v1 address, .footer-v1 .link-list a, .footer-v1 .link-list i {
    color: #555;
}

.footer-v1 .copyright {
    background-color: #3b0044;
}


.footer-v1 .copyright a {
    color: #ffffff;
}

.footer-v1 .latest-list li {
    border-top: none;
}

.picto_button {
    height: 40px;
    width: 40px;
}

.line-pictos-projet .block-picto {
    display: inline-block;
    padding-right: 30px;
}

.line-pictos-projet img.pictos {
    width: 30px;
    height: 30px;
}

.line-pictos-projet h4 {
    padding: 5px 0 0 0;
    margin: 0;
    font-size: 12px;
    line-height: 12px;
}

img.image-75 {
    width: 75px;
    height: 75px;
}

.blog-post-tags li {
    color: #555;
}

.pagination>li>a, .pagination>li>span {
    padding: 5px 12px;
}

@media (min-width: 992px) {
    .cube-portfolio .link-captions li.description-projet {
        padding: 0 10px;
    }

    .header-fixed .header-v1.header-fixed-shrink .navbar-nav > li > a, .header-v1 .navbar-default .navbar-nav > li > a {
        padding-left: 10px;
        padding-right: 20px;
    }

    .header-v1 .navbar-brand, .header-v1 .navbar-default .navbar-nav {
        margin-top: -10px;
    }

    .header-fixed-shrink .navbar-brand {
        margin-top: -7px;
    }

    .header-v1 .dropdown > a:after {
        right: 10px;
    }

    ul.padding-5 {
        padding: 5px 0;
    }
}
