/*----------------------------------------------------------------------

[-----*** TABLE OF CONTENT ***-----]

1. Header - Topbar
2. Header - Navbar
3. Header - Responsive Navbar Style
4. Header - Submenu
5. Header - Search
6. Header - Mega Menu
7. Header - Fixed
8. Header - Fixed Boxed

-----------------------------------------------------------------------*/


/*--------------------------------------------------
    [1. Header - Topbar]
----------------------------------------------------*/

/*Top Bar v1 (login, search etc.)
------------------------------------*/
.header-v1 .topbar-v1 {
	background: #fdfdfd;
	border-top: solid 1px #f0f0f0;
	border-bottom: solid 1px #f0f0f0;
}

/*Top Contacts*/
.header-v1 .topbar-v1 ul {
	margin: 0;
}

.header-v1 .topbar-v1 .top-v1-contacts li {
	color: #666;
	padding: 5px 0;
	font-size: 12px;
	margin-right: 15px;
	display: inline-block;
}

.header-v1 .topbar-v1 .top-v1-contacts i {
	color: #999;
	font-size: 13px;
	margin-right: 3px;
}

/*Top Data*/
.header-v1 .topbar-v1 .top-v1-data {
	text-align: right;
}

.header-v1 .topbar-v1 .top-v1-data li {
	padding: 5px 11px;
	margin-left: -4px;
	display: inline-block;	
	border-right: solid 1px #eee;
}

.header-v1 .topbar-v1 .top-v1-data li:first-child {
	margin-left: 0;
	border-left: solid 1px #eee;
}

.header-v1 .topbar-v1 .top-v1-data li a {
	font-size: 12px; 
}

.header-v1 .topbar-v1 .top-v1-data li a:hover {
	text-decoration: none;
}

.header-v1 .topbar-v1 .top-v1-data li i {
	color: #777;
	font-size: 13px;
}

.header-v1 .topbar-v1 .top-v1-data li a:hover i {
	color: #72c02c;
}

/*Responsive Topbar v1*/
@media (max-width: 991px) {
	.header-v1 .topbar-v1 ul {
		text-align: center !important;
	}

	.header-v1 .topbar-v1 .top-v1-contacts {
		margin: 10px 0;
	}

	.header-v1 .topbar-v1 .top-v1-data li {
		top: 1px;
		padding: 6px 16px;
		margin-left: -5px;
		position: relative;
		border: solid 1px #eee;
	}		
}


/*--------------------------------------------------
    [2. Header - Navbar]
----------------------------------------------------*/

/*Navbar*/
.header-v1 {
	z-index: 99;
	position: relative;
}

.header-v1 .navbar {
	margin: 0;
}

.header-v1 .navbar-default {
	border: none;
	background: none;
}

/*Navbar Header*/
.header-v1 .navbar-header {
	margin: 10px 0;
}

/*Navbar Brand*/
.header-v1 .navbar-brand { 
	z-index: 1;
	position: relative;
}

@media (max-width: 768px) {
	.header-v1 .navbar-brand {
		padding-left: 0;
	}
}

@media (max-width: 991px) {
	.header-v1 .navbar-brand {
		margin-top: -10px;
	}
}

@media (min-width: 992px) {
	.header-v1 .navbar-brand {
		margin-top: -4px;
	}
}

/*Navbar Toggle*/
.header-v1 .navbar-default .navbar-toggle {
	border-color: #5fb611;
}

@media (max-width: 768px) {
	.header-v1 .navbar-default .navbar-toggle {
		margin-right: 0;
	}
}

.header-v1 .navbar-default .navbar-toggle .fa {
	color: #fff;
	font-size: 19px;
}

.header-v1 .navbar-toggle,
.header-v1 .navbar-default .navbar-toggle:hover, 
.header-v1 .navbar-default .navbar-toggle:focus {
	background: #72c02c;
	padding: 6px 10px 2px;
}

.header-v1 .navbar-toggle:hover {
	background: #5fb611 !important;
}

/*Navbar Collapse*/
.header-v1 .navbar-collapse {
	position: relative;
}

/*Navbar Menu*/
@media (min-width: 992px) {
	.header-v1 .navbar-default .navbar-nav {
		margin-top: -6px;
	}
}

.header-v1 .navbar-default .navbar-nav > li > a {
	color: #687074;
	font-size: 15px;
	font-weight: 400;
	text-transform: uppercase;
}

@media (min-width: 992px) {
	.header-v1 .navbar-nav {
		float: right;
	}	
}


/*--------------------------------------------------
    [3. Header - Responsive Navbar Style]
----------------------------------------------------*/

/*Responsive Navbar*/
@media (max-width: 991px) {
	/*Responsive code for max-width: 991px*/
    .header-v1 .navbar-header {
        float: none;
    }
    
    .header-v1 .navbar-toggle {
        display: block;
    }
    
    .header-v1 .navbar-collapse.collapse {
        display: none !important;
    }
    
    .header-v1 .navbar-collapse.collapse.in {
        display: block !important;
  		overflow-y: auto !important;
    }
    
    .header-v1 .navbar-nav {
        margin: 0 0 5px;
        float: none !important;
    }

    .header-v1 .navbar-nav > li {
        float: none;
    }
    
    .header-v1 .navbar-nav > li > a {
        padding-top: 30px;
        padding-bottom: 40px;
    }

    /*Header*/
	.header-v1 {
		border-bottom: solid 1px #eee;
	}

	/*Pull Right*/
    .header-v1 .dropdown-menu.pull-right {
		float: none !important;
	}

	/*Dropdown Menu Slide Down Effect*/
	.header-v1 .navbar-nav .open .dropdown-menu {
		border: 0;
		float: none;
		width: auto;
		margin-top: 0;
		position: static;
		box-shadow: none;
		background-color: transparent;
	}

	.header-v1 .navbar-nav .open .dropdown-menu > li > a,
	.header-v1 .navbar-nav .open .dropdown-menu .dropdown-header {
		padding: 5px 15px 5px 25px;
	}
	
	.header-v1 .navbar-nav .open .dropdown-menu > li > a {
		line-height: 20px;
	}
	
	.header-v1 .navbar-nav .open .dropdown-menu > li > a:hover,
	.header-v1 .navbar-nav .open .dropdown-menu > li > a:focus {
		background-image: none;
	}

	.header-v1 .navbar-default .navbar-nav .open .dropdown-menu > li > a {
		color: #777;
	}

	.header-v1 .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
	.header-v1 .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
		color: #333;
		background-color: transparent;
	}
	
	.header-v1 .navbar-default .navbar-nav .open .dropdown-menu > .active > a,
	.header-v1 .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover,
	.header-v1 .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
		color: #555;
		background-color: #e7e7e7;
	}
	
	.header-v1 .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a,
	.header-v1 .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover,
	.header-v1 .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus {
		color: #ccc;
		background-color: transparent;
	}	

	.header-v1 .navbar-default .dropdown-menu.no-bottom-space {
		padding-bottom: 0;
	}

  	/*Design for max-width: 991px*/
  	.header-v1 .navbar-collapse,
	.header-v1 .navbar-collapse .container {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	.header-v1 .navbar-default .navbar-nav > li > a {
		font-size: 14px;
		padding: 9px 10px;
	}

	.header-v1 .navbar-default .navbar-nav > li a {
		border-bottom: solid 1px #eee;
	}	

	.header-v1 .navbar-default .navbar-nav > li > a:focus {
		background: none;
	}

	.header-v1 .navbar-default .navbar-nav > li > a:hover {
		color: #72c02c;
	}

	.header-v1 .navbar-default .navbar-nav > .active > a,
	.header-v1 .navbar-default .navbar-nav > .active > a:hover,
	.header-v1 .navbar-default .navbar-nav > .active > a:focus {
		background: #72c02c;
		color: #fff !important;	
	}

	.header-v1 .dropdown .dropdown-submenu > a { 
		font-size: 13px;
		color: #333 !important;
		text-transform: uppercase;
	}
}

@media (min-width: 992px) {	
	/*Navbar Collapse*/
	.header-v1 .navbar-collapse {
		padding: 20px 0 0;
	}

	/*Navbar*/
	.header-v1 .navbar {
		min-height: 40px !important;
	}

	.header-v1 .container > .navbar-header, 
	.header-v1 .container-fluid > .navbar-header, 
	.header-v1 .container > .navbar-collapse, 
	.header-v1 .container-fluid > .navbar-collapse {
		margin-bottom: -10px;
	}

	.header-v1 .navbar-nav {
		top: 2px;
		position: relative;
	}

	.header-v1 .navbar-default .navbar-nav > li {
		margin-left: 1px;
	}

	.header-v1 .navbar-default .navbar-nav > li > a {
		bottom: -2px;
		position: relative;
		padding: 15px 30px 17px 20px;
	}

	.header-v1 .navbar-default .navbar-nav > li > a,
	.header-v1 .navbar-default .navbar-nav > li > a:hover,
	.header-v1 .navbar-default .navbar-nav > li > a:focus {
		background: none;
	}
	
	.header-v1 .navbar-default .navbar-nav > .active > a,
	.header-v1 .navbar-default .navbar-nav > li > a:hover,
	.header-v1 .navbar-default .navbar-nav > li > a:focus {
		color: #72c02c;
		bottom: -2px;
		position: relative;
	}

	.header-v1 .navbar-default .navbar-nav > li:hover > a {
        color: #72c02c;
    }

	/*Dropdown Menu*/
	.header-v1 .dropdown-menu { 
		padding: 0;
		border: none;
		min-width: 200px;
		border-radius: 0; 
		z-index: 1000 !important;
		margin-top: -2px !important;
		border-top: solid 2px #72c02c; 
		border-bottom: solid 2px #687074; 
	}

	.header-v1 .dropdown-menu li a { 
		color: #687074; 
		font-size: 13px; 
		font-weight: 400; 
		padding: 6px 15px;
		border-bottom: solid 1px #eee;
	}

	.header-v1 .dropdown-menu .active > a,
	.header-v1 .dropdown-menu li > a:hover {
		color: #fff;
		filter: none !important;
		background: #687074 !important;
		-webkit-transition: all 0.1s ease-in-out;
		-moz-transition: all 0.1s ease-in-out;
		-o-transition: all 0.1s ease-in-out;
		transition: all 0.1s ease-in-out;
	}

	.header-v1 .dropdown-menu li > a:focus {
		background: none;
		filter: none !important;
	}

	.header-v1 .navbar-nav > li.dropdown:hover > .dropdown-menu {
		display: block;
	}	

	.header-v1 .open > .dropdown-menu {
		display: none;
	}

	/*Search*/
	.header-v1 .navbar .search-open {
		width: 330px;
	}
}


/*--------------------------------------------------
    [4. Header - Submenu]
----------------------------------------------------*/

/*Dropdown Submenu for BS3
------------------------------------*/
.header-v1 .dropdown-submenu { 
   position: relative; 
}

.header-v1 .dropdown > a:after,
.header-v1 .dropdown-submenu > a:after {
    top: 8px;
    right: 9px;
    font-size: 11px;
    content: "\f105";
    position: absolute;
    font-weight: normal;
    display: inline-block;
    font-family: FontAwesome;
}

@media (max-width: 991px) {
  	.header-v1 .dropdown-submenu > a:after {
      	content: " ";
  	}
}

.header-v1 .dropdown > a:after {
    top: 16px;
    right: 15px;
    content: "\f107";
}

@media (max-width: 991px) {
  	.header-v1 .dropdown > a:after {
	    top: 11px;
	}
}

.header-v1 .dropdown-submenu > .dropdown-menu { 
	top: 0; 
	left: 100%; 
	margin-top: -5px; 
	margin-left: 0px; 
}

/*Submenu comes from LEFT side*/
.header-v1 .dropdown-submenu > .dropdown-menu.submenu-left {
	left: -100%;
}

.header-v1 .dropdown-submenu:hover > .dropdown-menu {  
   	display: block;
}

@media (max-width: 991px) {
	.header-v1 .dropdown-submenu > .dropdown-menu {  
		display: block;
		margin-left: 15px;
	}
}

.header-v1 .dropdown-submenu.pull-left {
	float: none;
}

.header-v1 .dropdown-submenu.pull-left > .dropdown-menu {
	left: -100%;
	margin-left: 10px;
}

.header-v1 .dropdown-menu li [class^="fa-"],
.header-v1 .dropdown-menu li [class*=" fa-"] {
	left: -3px;
	width: 1.25em;
	margin-right: 1px;
	position: relative;
	text-align: center;
	display: inline-block;
}
.header-v1 .dropdown-menu li [class^="fa-"].fa-lg,
.header-v1 .dropdown-menu li [class*=" fa-"].fa-lg {
	/* increased font size for fa-lg */
	width: 1.5625em;
}


/*--------------------------------------------------
    [5. Header - Search]
----------------------------------------------------*/

/*Search Box
------------------------------------*/
/*
.header-v1 .navbar .nav > li > .search {
	color: #aaa;
	cursor: pointer;
	font-size: 15px;
	min-width: 30px;
	padding: 18px 0;
	text-align: center;
	display: inline-block;
}

.header-v1 .navbar .nav > li > .search:hover {
	color: #72c02c;
}

.header-v1 .navbar .search-open {
	right: 0; 
	top: 51px; 
	display: none;
	padding: 14px; 
	position: absolute;
	background: #fcfcfc; 
	box-shadow: 0 0 3px #ddd; 
}

.header-v1 .navbar .search-open form {
	margin: 0;	
}

@media (min-width: 767px) and (max-width: 991px) {
	.header-v1 .navbar > .container .navbar-brand, 
	.header-v1 .navbar > .container-fluid .navbar-brand {
		margin-left: -10px;
	}

	.header-v1 .navbar-toggle {
		margin-right: 0;
	}

	.header-v1 .navbar .search-open {
		width: 93%;
	}
}

@media (max-width: 991px) {
	.header-v1 .navbar .nav > li > .search {
		color: #999;
		height: 34px;
		min-width: 35px;
		text-align: left;
		background: #eee;
		line-height: 12px;
		text-align: center;
		margin: 5px 10px 0;
		border-bottom: none;
		padding: 11px 0 12px;
	}

	.header-v1 .navbar .nav > li > .search:hover {
		background: #5fb611;
		color: #fff !important;
	}

	.header-v1 .navbar .search-open {
		top: 5px;
		padding: 0;
		left: 48px;
		border-top: none;
		box-shadow: none;
		background: none; 
		margin-right: 10px;
	}
}
*/

/*--------------------------------------------------
    [6. Header - Mega Menu]
----------------------------------------------------*/

/*Mega Menu
------------------------------------*/
/*
.header-v1 .mega-menu .nav,
.header-v1 .mega-menu .dropup,
.header-v1 .mega-menu .dropdown,
.header-v1 .mega-menu .collapse {
  	position: static;
}

.header-v1 .mega-menu .navbar-inner,
.header-v1 .mega-menu .container {
  	position: relative;
}

.header-v1 .mega-menu .dropdown-menu {
  	left: auto;
}

.header-v1 .mega-menu .dropdown-menu > li {
  	display: block;
}

.header-v1 .mega-menu .dropdown-submenu .dropdown-menu {
  	left: 100%;
}

.header-v1 .mega-menu .nav.pull-right .dropdown-menu {
  	right: 0;
}

.header-v1 .mega-menu .mega-menu-content {
  	*zoom: 1;
  	padding: 0;
}

.header-v1 .mega-menu .mega-menu-content:before,
.header-v1 .mega-menu .mega-menu-content:after {
  	content: "";
  	display: table;
  	line-height: 0;
}

.header-v1 .mega-menu .mega-menu-content:after {
  	clear: both;
}

.header-v1 .mega-menu.navbar .nav > li > .dropdown-menu:after,
.header-v1 .mega-menu.navbar .nav > li > .dropdown-menu:before {
  	display: none;
}

.header-v1 .mega-menu .dropdown.mega-menu-fullwidth .dropdown-menu {
  	left: 0;
  	right: 0;
  	overflow: hidden;
}

@media (min-width: 992px) {
	.header-v1 .mega-menu .dropdown.mega-menu-fullwidth .dropdown-menu {
  		margin-left: 15px;
  		margin-right: 15px;
	}
}

@media (min-width: 992px) {
	.header-v1 .mega-menu .equal-height {
	    display: -webkit-flex;
	    display: -ms-flexbox;
	    display: flex;
	    
	}

	.header-v1 .mega-menu .equal-height-in {
	    display: -webkit-flex;
	    display: -ms-flexbox;
	    display: flex;
	}
}

.header-v1 .mega-menu .equal-height-list h3 {
	font-size: 15px;
	padding: 0 10px;
	font-weight: 400;
	text-transform: uppercase;
}

@media (min-width: 992px) {
	.header-v1 .mega-menu .big-screen-space {
		margin-bottom: 20px;
	}
}

@media (min-width: 992px) {
    .header-v1 .mega-menu .equal-height-in {
        padding: 20px 0;
        border-left: 1px solid #eee;
    }

    .header-v1 .mega-menu .equal-height-in:first-child {
        border-left: none;
        margin-left: -1px;
    }

    .header-v1 .mega-menu .equal-height-list {
    	width: 100%;
    }

    .header-v1 .mega-menu .equal-height-list li a {
        display: block;
        position: relative;
        border-bottom: none;
        padding: 5px 10px 5px 15px;
    }

    .header-v1 .mega-menu .equal-height-list a:hover {
    	text-decoration: none;
    }
    
    .header-v1 .mega-menu .mega-menu-fullwidth li a {
    	padding: 5px 10px 5px 30px;	
    }

    .header-v1 .mega-menu .mega-menu-fullwidth li a:after {
        top: 7px;
        left: 15px;
        font-size: 11px;
        content: "\f105";
        position: absolute;
        font-weight: normal;
        display: inline-block;
        font-family: FontAwesome;
    }

    .header-v1 .mega-menu .mega-menu-fullwidth .disable-icons li a {
    	padding: 5px 10px 5px 15px;
    }

    .header-v1 .mega-menu .mega-menu-fullwidth .disable-icons li a:after {
    	display: none;
    }
}

@media (min-width: 737px) and (max-width: 991px) {
	.header-v1 .mega-menu .mega-menu-content .container {
		width: 690px !important;
	}

	.header-v1 .mega-menu .equal-height {
		margin-right: 0;
	}

	.header-v1 .mega-menu .equal-height-in {
    	padding-right: 0;
    	margin-right: -15px;
    }
}

@media (max-width: 991px) {
	.header-v1 .mega-menu .equal-height {
		margin-right: 0;
	}

	.header-v1 .mega-menu .equal-height-in {
    	padding-right: 0;
    }
}

@media (max-width: 991px) {
    .header-v1 .mega-menu .dropdown.mega-menu-fullwidth .dropdown-menu {
        width: auto;
    }

    .header-v1 .mega-menu .equal-height-in,
    .header-v1 .mega-menu .equal-height-list {
        display: block;
    }

    .header-v1 .mega-menu .mega-menu-fullwidth .dropdown-menu > li > ul {
        display: block;
    }

    .header-v1 .mega-menu .equal-height-list h3 {
		color: #333; 
		margin: 0 0 5px;
		font-size: 13px; 
		font-weight: 400; 
		padding: 6px 25px 5px; 
		border-bottom: solid 1px #eee;
	}

	.header-v1 .mega-menu .equal-height-list li {
		margin-left: 15px;
	}

	.header-v1 .mega-menu .equal-height-list li a {
		color: #687074;
		display: block;
		font-size: 13px; 
		font-weight: 400; 
		padding: 6px 25px; 
		border-bottom: solid 1px #eee;
	}

	.header-v1 .mega-menu .equal-height-list > h3.active,
	.header-v1 .mega-menu .equal-height-list > .active > a,
	.header-v1 .mega-menu .equal-height-list > .active > a:hover,
	.header-v1 .mega-menu .equal-height-list > .active > a:focus {
		color: #555;
		background-color: #e7e7e7;
	}

	.header-v1 .mega-menu .equal-height-list li a:hover {
		color: #333;
		text-decoration: none;
	}
}

*/
/*--------------------------------------------------
    [7. Header - Fixed]
----------------------------------------------------*/

/*Header*/
.header-fixed .header-v1.header-fixed-shrink {
	top: 0;
	left: 0;
	width: 100%;
	height: 95px;
	z-index: 1000;
	position: fixed;
	margin-top: -35px;
	border-bottom: none;
	box-shadow: 0 0 3px #bbb;
	background: rgba(255,255,255, 0.96); 
	-webkit-transition: height 0.4s;
	-moz-transition: height 0.4s;
	transition: height 0.4s;
}

/*Topbar v2*/
.header-fixed .header-v1.header-fixed-shrink .topbar-v1 {
	display: none;
}

/*Brand*/
.header-fixed .header-v1.header-fixed-shrink .navbar-brand { 
	top: 41px;
	z-index: 1;
	position: relative;
	padding: 0 10px 10px;
}

.header-fixed .header-v1.header-fixed-shrink .navbar-brand img {
	width: 90%;
}

.header-fixed .header-v1.header-fixed-shrink .search,
.header-fixed .header-v1.header-fixed-shrink .active a {
	border-bottom: none !important;
}

.header-fixed .header-v1.header-fixed-shrink .navbar-nav > li > .dropdown-menu {
	margin-top: 2px;
}

@media (max-width: 991px) {
	/*Header*/
	.header-fixed .header-v1,
	.header-fixed .header-v1.header-fixed-shrink {
		height: inherit;
		position: relative;
	}
}

@media (min-width: 992px) {
	.header-fixed .header-v1.header-fixed-shrink .navbar-nav > li > a {
		padding: 40px 30px 20px 20px;
		border-bottom: solid 1px transparent !important;
	}

	.header-fixed .header-v1.header-fixed-shrink .dropdown > a:after {
		top: 41px;
	}

	/*Search Bar*/
	.header-fixed .header-v1.header-fixed-shrink .search {
		margin-top: 35px;
		color: #585f69 !important;
		background: none !important;
		padding: 8px 8px 8px 3px !important;
	}

	.header-fixed .header-v1.header-fixed-shrink .search-open {
		top: 81px;
		border-top: none;
		background: rgba(255,255,255, 0.9);	
	}
}


/*--------------------------------------------------
    [8. Header - Fixed Boxed]
----------------------------------------------------*/

.boxed-layout.header-fixed .header-v1.header-sticky {
	left: inherit;
	width: inherit;
}