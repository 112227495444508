.map {
    width: 100%;
    height: 450px;
    border-top: solid 1px #eee;
    border-bottom: solid 1px #eee;
}

.purchase {
    padding: 25px 0 30px;
    border-bottom: solid 1px #eee;
    background: url(../img/patterns/breadcrumbs.png) repeat;
}

.purchase p {
    margin-bottom: 0;
}

.purchase span {
    color: #666;
    display: block;
    font-size: 24px;
    line-height: 35px;
    font-weight: normal;
    margin-bottom: 12px;
    font-family: "Open Sans", Arial, sans-serif;
}


@media (max-width: 992px) {
    .purchase {
        text-align: center;
    }

    .purchase span {
        font-size: 22px;
        line-height: 29px;
    }

    .purchase .btn-buy a {
        margin-top: 25px;
        font-size: 16px;
    }
}


/*--------------------------------------------------
  [39. Revolution Slider]
----------------------------------------------------*/
.tp-dottedoverlay {
    z-index: 1 !important;
}

.tp-bullets.preview4 .bullet {
    border-radius: 10px !important;
}

.tp-banner-container ul li,
.fullscreenbanner-container ul li {
    list-style: none;
    visibility: hidden;
}

.tp-banner-container a.btn-u,
.tp-banner-container a.btn-u:hover {
    color: #fff;
    border: solid 1px transparent;
}

.tp-banner-container a.re-btn-brd {
    background: none;
    border: solid 1px #fff;
}

.tp-banner-container a.re-btn-brd:hover {
    color: #555;
    background: #fff;
}

/*Fullwidth*/
.tp-banner-container .btn-u.btn-brd {
    font-weight: 400;
}

.tp-banner-container {
    position: relative;
}

/*Fullscreen*/
.fullscreen-container {
    padding:0;
    width:100%;
    position:relative;
}

/*Slider Contents v1*/
.revolution-mch-1:after {
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    content: ' ';
    position: absolute;
    background: rgba(0,0,0,0.3);
}

.revolution-ch1 {
    color: #fff;
    font-size: 40px;
    font-weight: 400;
    line-height: 60px;
    text-transform: uppercase;
    font-family: "Open Sans", Arial, sans-serif;
}

.revolution-ch2 {
    color: #fff;
    font-size: 20px;
    max-width: 470px;
    line-height: 32px;
    position: relative;
    text-align: center;
    font-weight: normal;
    font-family: "Open Sans", Arial, sans-serif;
}

.revolution-ch2:after {
    left: 50%;
    width: 70px;
    height: 2px;
    content: ' ';
    bottom: -20px;
    background: #fff;
    margin-left: -35px;
    position: absolute;
}

/*Slider Contents v2*/
.re-title-v1 {
    color: #fff;
    font-size: 75px;
    font-weight: 600;
    line-height: 60px;
    text-transform: uppercase;
    font-family: "Open Sans", Arial, sans-serif;
}

.re-title-v2 {
    color: #fff;
    font-size: 40px;
    font-weight: 600;
    line-height: 45px;
    text-align: center;
    text-transform: uppercase;
    font-family: "Open Sans", Arial, sans-serif;
}

.re-text-v1 {
    color: #fff;
    font-size: 24px;
    text-align: center;
    font-family: "Open Sans", Arial, sans-serif;
}

.re-text-v2 {
    color: #fff;
    font-size: 24px;
    font-family: "Open Sans", Arial, sans-serif;
}

/*Slides*/
.rs-caption-1 {
    color: #fff;
    font-size: 32px;
    font-weight: 200;
    padding: 6px 75px;
    position: relative;
    border-top: solid 1px #fff;
    border-bottom: solid 1px #fff;
}

.rs-caption-1:after,
.rs-caption-1:before {
    font-size: 16px;
    content: "\f005";
    position: absolute;
    font-family: FontAwesome;
}

@media (max-width: 768px) {
    .rs-caption-1:after,
    .rs-caption-1:before {
        content: "";
    }
}

.rs-caption-1:after {
    right: 4%;
}

.rs-caption-1:before {
    left: 4%;
}

.rs-caption-2 {
    color: #fff;
    position: relative;
    font-size: 26px;
    font-weight: 200;
    line-height: 40px;
    padding: 10px 50px;
    text-align: center;
}

.rs-caption-2:after {
    left: 50%;
    content: "";
    height: 1px;
    width: 100px;
    bottom: -10px;
    background: #fff;
    margin-left: -50px;
    position: absolute;
}

.rs-caption-3 a {
    margin: 0 5px;
    padding: 7px 20px;
    text-transform: uppercase;
}

@media (max-width: 768px) {
    .rs-caption-1,
    .rs-caption-2,
    .rs-caption-3 {
        font-weight: normal;
    }
}